import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function BlogIndexPage(p) {
  const title = "Blog | Manchester Students Group";
  const description = "";

  return (
    <main className="blog-page">
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: p.location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />

      <div className="blog">
        <div className="card">
          <div className="card__content">
            <h2>Articles</h2>
            <div>
              <div className="home__events-grid">
                <Link to="/articles/new-zealand-wines-wins-independent-retailer-of-the-year">
                  <div className="event-teaser">
                    <img src="/wine.jpg" alt="" />
                    <h3>
                      New Zealand Wines wins Independent Retailer of the Year
                    </h3>
                    <div className="event-teaser__content">
                      Guide / 2 minute read
                    </div>
                  </div>
                </Link>
                <Link to="/articles/18-things-i-wish-i-knew-in-first-year">
                  <div className="event-teaser">
                    <img src="/18things.jpg" alt="" />
                    <h3>18 things i wish i knew in first year</h3>
                    <div className="event-teaser__content">
                      Isla moore / guide / 2 minute read
                    </div>
                  </div>
                </Link>
                <Link to="/articles/advice-from-a-third-year-to-a-fresher">
                  <div className="event-teaser">
                    <img src="/advice.jpg" alt="" />
                    <h3>Advice from a third year to a fresher</h3>
                    <div className="event-teaser__content">
                      Molly Sword / Guide / 5 minute read
                    </div>
                  </div>
                </Link>
                <Link to="/articles/freshers-week-dos-and-donts">
                  <div className="event-teaser">
                    <img src="/dosandonts.jpg" alt="" />
                    <h3>The Do’s and Dont’s of Freshers week</h3>
                    <div className="event-teaser__content">
                      Euan Walker / Guide / 3 minute read
                    </div>
                  </div>
                </Link>
                <Link to="/articles/fallowfields-most-iconic-streets">
                  <div className="event-teaser">
                    <img src="/fallowfields.jpg" alt="" />
                    <h3>Fallowfield’s most iconic streets</h3>
                    <div className="event-teaser__content">
                      Leo Simpson / Guide / 3 minute read
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
